*{
  animation: fadein 5s;

}
.coming-soon-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.lottie-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* This makes sure the animation stays in the background */
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.overlay-image {
  max-width: 200px;
  margin-bottom: 20px;
}

.email-signup input {
  padding: 10px;
  margin: 10px 0;
  width: 300px;
}

.email-signup button {
  padding: 12px;
  margin-left: 5px;
  background-color: #e58339;
  color: #fff;
  border: none;
  cursor: pointer;
}

.loader {
  margin-top: 20px;
}

.message {
  margin-top: 10px;
  color: green;
}
